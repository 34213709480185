/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
// import headerOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";
import intl from "react-intl-universal";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import { useLocation, useParams } from "react-router-dom";
// import { useQueryLang } from "@/preset";
// const language = useQueryLang();
const pathnameNameList = {
  zh: {
    "/yifu/zh/companyEstablishment": {
      name: "CompanyEstablishment",
      content: "CompanyAllContent",
      time: "2023-7-6",
    },
    "/yifu/zh/productLaunch": {
      name: "ProductLaunch",
      content: "ProductLaunchAllContent",
      time: "2023-5-28",
    },
    "/yifu/zh/workLayout": {
      name: "WorkLayout",
      content: "WorkLayoutAllContent",
      time: "2023-5-30",
    },
  },
  en: {
    "/yifu/en/companyEstablishment": {
      name: "CompanyEstablishment",
      content: "CompanyAllContent",
      time: "2023-7-6",
    },
    "/yifu/en/productLaunch": {
      name: "ProductLaunch",
      content: "ProductLaunchAllContent",
      time: "2023-5-28",
    },
    "/yifu/en/workLayout": {
      name: "WorkLayout",
      content: "WorkLayoutAllContent",
      time: "2023-5-30",
    },
  },
};
function CompanyEstablishment() {
  const { pathname } = useLocation();
  const { lang } = useParams();
  console.log("location", pathname);
  return (
    <BaseLayout
      title={intl.get(pathnameNameList[lang][pathname].name)}
      breadcrumb={[
        { label: intl.get("Home"), route: `/yifu/${lang}` },
        { label: intl.get(pathnameNameList[lang][pathname].name) },
      ]}
    >
      <MKTypography
        variant="caption"
        fontWeight="regular"
        lineHeight={1}
        textAlign="center"
        color="text"
        my={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Icon>schedule</Icon>&nbsp;{pathnameNameList[lang][pathname].time}
      </MKTypography>
      <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
        <div
          dangerouslySetInnerHTML={{ __html: intl.get(pathnameNameList[lang][pathname].content) }}
        />
      </MKTypography>
    </BaseLayout>
  );
}

export default CompanyEstablishment;
