// import axios from "axios";
// import jsonp from "jsonp";
// import { Toast } from "antd-mobile"
import intl from "react-intl-universal";
import EN from "./locale/en.json";
import CH from "./locale/zh.json";
// import qs from "qs";
// import { useLocation } from "react-router-dom";

export const useQueryLang = () => {
  // const location = useLocation();
  let lang = "zh";
  if (window.location.pathname.indexOf("/en") > -1) {
    lang = "en";
  }
  // console.log("useLocation", location);
  return lang;
};

// 配置国际化
const locales = {
  en_US: EN,
  zh_CN: CH,
};

// const { lang } = qs.parse(window.location.search.split("?")[1]);

const language = useQueryLang() === "zh" ? "zh_CN" : "en_US";

intl.init({
  warningHandler: (msg, detail) => {
    console.log(`${msg} ${detail}`);
  },
  currentLocale: language,
  locales,
});
