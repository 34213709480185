/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { useState } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
// import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import { message } from "antd";
// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";
import patatoData from "pages/Presentation/sections/data/patatoData";
import intl from "react-intl-universal";

const renderData = data.map(({ image, name, route }) => (
  <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
    <Link to={route}>
      <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
    </Link>
  </Grid>
));
const RenderPatatoData = () => {
  // const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const handleClick = (route) => {
    console.log("route", route);
    if (route) {
      // navigate(route);
      window.open(route);
      return;
    }
    messageApi.info(intl.get("development"));
  };
  return (
    <>
      {contextHolder}
      {patatoData.map(({ image, name, route }) => (
        <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
          <ExampleCard
            onClick={() => handleClick(route)}
            image={image}
            name={name}
            display="grid"
            minHeight="auto"
          />
        </Grid>
      ))}
    </>
  );
};
function Pages() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="boost creativity"
            container
            sx={{ mb: 2 }}
          /> */}
          <MKTypography id="Products" variant="h2" fontWeight="bold">
            {intl.get("Products")}
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                {intl.get("APPName")}
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                {intl.get("APPDescription")}
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 6, lg: 12 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              <RenderPatatoData />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                {intl.get("SweetPotato")}
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                <p dangerouslySetInnerHTML={{ __html: intl.get("SweetPotatoDescription") }} />
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
