/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "images/pic_top.png";
import intl from "react-intl-universal";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route:
            "https://apps.apple.com/cn/app/alclient-alist-%E6%96%87%E4%BB%B6%E7%AE%A1%E7%90%86/id6449257429?platform=iphone",
          label: intl.get("DownloadButton"),
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
            <Grid item xs={12} md={12}>
              <MKTypography
                variant="h1"
                color="white"
                id="Introduce"
                textAlign="center"
                mt={-6}
                mb={0}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {intl.get("Slogan")}
              </MKTypography>
            </Grid>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              {intl.get("SloganSubTitle1")}
              <br />
              {intl.get("SloganSubTitle2")}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        {/* <DesignBlocks /> */}
        <Pages />
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}
        {/* <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Let"s start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI"s faster."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}
        <Testimonials />
        {/* <Download /> */}
        <MKBox pt={0} pb={6}>
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={6}
              pb={4}
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography variant="h2" id="Contact">
                {intl.get("Contact")}
              </MKTypography>
            </Grid>
            <Grid
              container
              xs={12}
              lg={8}
              item
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="body1"
                color="dark"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                {intl.get("Address")}
              </MKTypography>
              {/* <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "center" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid> */}
              <Grid
                item
                xs={12}
                lg={12}
                pt={12}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 5, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "center" } }}
              >
                <MKSocialButton
                  component="a"
                  href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=ipElRDCv77NfLd08lNT2IpcGYGEOyHW6&authKey=ZS2ommdo6immbPLBopctB1bFi5lnZjYEySYTbbkbnHxajw49fKqvlyHsoxcjB%2F6D&noverify=0&group_code=468195459"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-qq" />
                  &nbsp;QQ:468195459
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="mailto:YiFuTechnology@outlook.com"
                  target="_blank"
                  color="pinterest"
                  sx={{ mr: 1 }}
                >
                  <EmailIcon />
                  &nbsp;Email:YiFuTechnology@outlook.com
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://github.com/BFWXKJGS/AlistClient"
                  target="_blank"
                  color="github"
                >
                  <GitHubIcon />
                  &nbsp;Github
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
