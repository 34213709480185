/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import { useMemo } from "react";
// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import intl from "react-intl-universal";
import dayjs from "dayjs";
function Counters() {
  const days = useMemo(() => dayjs().diff("2023-07-06", "day"), []);
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={days}
              suffix="+"
              title={intl.get("Days")}
              // description=""
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} /> */}
            <DefaultCounterCard
              count={2}
              // suffix="+"
              title={intl.get("Products")}
              // description=""
            />
            {/* <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} /> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={10000}
              suffix="+"
              title={intl.get("Users")}
              // description=""
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
