/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import intl from "react-intl-universal";
import androidImage from "images/product_sweetpotato_android.png";
import iosImage from "images/product_sweetpotato_ios.png";

export default [
  {
    image: iosImage,
    name: intl.get("AppStoreDownload"),
    route: "",
  },
  {
    image: androidImage,
    name: intl.get("AndroidDownload"),
    route: "https://www.geektang.cn/img/app/sweetpotato_100.apk",
  },
  // {
  //   image:
  //     "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/signin-basic.jpg",
  //   name: "Sign In Page",
  //   route: "/pages/authentication/sign-in",
  // },
  // {
  //   image: `${imagesPrefix}/author.jpg`,
  //   name: "Author Page",
  //   route: "/pages/landing-pages/author",
  // },
];
