/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import beian from "../../../images/beian.png";

function DefaultFooter({ content }) {
  const { copyright } = content;
  // const { search } = useLocation();
  // console.log("%c [ search ]-34", "font-size:13px; background:pink; color:#bf2c9f;", search);
  const navigate = useNavigate();
  const { lang } = useParams();
  // const { brand, socials, menus, copyright } = content;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    // const currentSearch = search.indexOf(value) > -1 ? search : `?lang=${value}`;
    // window.location.href = ""
    navigate(`/yifu/${value}`);
    window.location.reload();
  };
  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid> */}
          {/* {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))} */}
          <Select
            defaultValue={lang || "zh"}
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "en", label: "English" },
              { value: "zh", label: "中文" },
            ]}
          />
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography
              display="inline-block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              {copyright}
            </MKTypography>
            &nbsp;&nbsp;
            <MKTypography
              display="inline-block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
              component="a"
              href="https://beian.miit.gov.cn"
              target="_blank"
              rel="noreferrer"
            >
              浙ICP备2024064555号-1
            </MKTypography>
            &nbsp;&nbsp;
            <MKTypography
              display="inline-block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
              component="a"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=33010602013474"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <img
                  style={{ width: "16px", verticalAlign: "top", marginRight: "5px" }}
                  src={beian}
                  alt="浙公网安备"
                />
                浙公网安备33010602013474
              </div>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
